import React from 'react';

export const Input = React.forwardRef(({ type, name, error, ...props }, ref) => {
    return (
        <div className='w-full'>
            <input
                ref={ref}
                className={`border-b outline-none w-full border-b-gray py-[22px] lg:py-[29px] font-poppins text-base font-normal placeholder:text-base placeholder:text-cyan-blue transition-all duration-200 placeholder:capitalize`}
                {...props}
                type={type ? type : "text"}
                name={name}
                placeholder={`${name}*`}
            />
            <span className='text-red-700 text-sm font-poppins'>{error}</span>
        </div>
    );
});