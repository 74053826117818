import React, { useState } from "react";
import carDriver from "../assets/images/webp/car-driver.webp";
import PrimaryHeading from "./common/PrimaryHeading";

const GigWorker = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Function to open the modal
  const openModal = () => setIsModalOpen(true);

  // Function to close the modal
  const closeModal = () => setIsModalOpen(false);
  return (
    <div className="md:pt-[130px] pt-[107px] relative">
      <div className="lg:size-[204px] size-[94px] bg-tron-blue lg:blur-[150px] blur-[100px] rounded-full absolute lg:-right-[100px] md:-right-12 max-md:-left-9 md:top-[100px] top-14 pointer-events-none blur-ellipse"></div>
      <div className="container px-5 mx-auto xl:max-w-[1180px] flex xl:gap-[68px] gap-14 lg:items-center lg:flex-row flex-col-reverse" id="about">
        <div className="lg:max-w-[514px] w-full">
          <img
            className="lg:min-w-[514px] lg:min-h-[416px] sm:w-[491px] sm:h-[416px]  mx-auto"
            src={carDriver}
            alt="car-driver"
          />
        </div>
        <div className="lg:max-w-[558px] w-full">
          <PrimaryHeading
            className="[text-shadow:0px_0px_7.2px_#00000030] sm:mb-2.5 mb-[18px] font-jost lg:text-start text-center"
            Children="Are You a Gig Worker? Uber, Lyft, DoorDash, Fiverr, or Contractor?"
          />
          <p className="font-poppins font-normal text-base leading-lh_150 text-cyan-blue sm:mt-2.5 mt-[18px] mb-3 text-opacity-70 lg:text-start text-center">
            Gig work offers flexibility and freedom, but managing expenses,
            invoices, and keeping track of costs can be overwhelming. Whether
            you drive for Uber, deliver with DoorDash, or work freelance through
            Fiverr, having control over your finances is crucial to maximizing
            your income. Our service helps gig workers like you reduce your cell
            phone bill by up to 70%, so you can keep more of your hard-earned
            money. Let us handle the savings, while you focus on what you do
            best.
          </p>
          <p className="font-bold text-2xl sm:leading-lh_145 leading-lh_145 font-jost text-cyan-blue xl:pe-10 lg:text-start text-center">
            Book an appointment or call us now for special deals just for you!
          </p>
          <div className="flex lg:justify-start justify-center">
            <button 
              onClick={openModal} 
              aria-label="get-start"
              className="mt-8 font-semibold w-full max-w-[375px] sm:max-w-[165px] text-base leading-lh_145 font-jost text-navy-blue bg-tron-blue rounded-full px-5 py-[13.6px] border border-transparent border-solid hover:border-cyan-blue hover:bg-transparent transition-all duration-300 text-center"
            >
              Get Started Now
            </button>
          </div>
        </div>
      </div>
      
      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[100]">
          <div className="bg-white  relative rounded-lg sm:h-auto h-full w-[100%] sm:w-[700px]">
            <button
              onClick={closeModal}
              className="absolute top-[2px] sm:top-5  sm:pb-1  z-[101] bg-white  rounded-full text-3xl px-2.5 left-[20px] sm:left-5  font-bold text-black"
            >
              &times;
            </button>
            <iframe
              src="https://form.jotform.com/242966086554165"
              className="w-full h-full sm:h-[600px] border-none"
              title="Get Started Form"
            />
          </div>
        </div>
      )}

    </div>
  );
};

export default GigWorker;
