import React, { useState, useEffect } from "react";
import heroCircle from "../assets/images/png/hero-circle.png";
import blackStars from "../assets/images/svg/black-stars.svg";

const Hero = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showButton, setShowButton] = useState(false);

  // Function to open the modal
  const openModal = () => setIsModalOpen(true);

  // Function to close the modal
  const closeModal = () => setIsModalOpen(false);

  // Function to handle scroll position
  const handleScroll = () => {
    if (window.scrollY >= 534) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  // Add event listener for scroll
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="md:pt-[104px] overflow-x-hidden relative pt-24">
      {/* <div className="size-4 bg-tron-blue rounded-full absolute top-[124px] right-[204px] pointer-events-none xl:block hidden"></div> */}
      <img
        className="animate-spin-slow xl:size-[161px] md:size-[80px] size-[70px] sm:rotate-0 rotate-[220deg] absolute lg:left-10 sm:left-0 lg:top-[130px] md:top-[150px] -left-8 top-9 pointer-events-none"
        src={heroCircle}
        alt="circle"
      />
      {/* <div className="lg:size-[204px] size-[100px] bg-tron-blue lg:blur-[150px] blur-[100px] rounded-full absolute lg:-right-[100px] -right-12 md:top-[130px] top-8 pointer-events-none blur-ellipse"></div> */}
      <div className="xl:max-w-[1180px] mx-auto px-5 container">
        <h1 className="max-w-[755px] text-center mx-auto font-bold sm:font-inter font-jost xl:!text-48 lg:text-5xl md:text-4xl text-4xl max-sm:[text-shadow:0px_0px_7.2px_#00000030] text-cyan-blue sm:!leading-lh_120 !leading-lh_145 sm:px-0 px-2.5 mb-6">
          Unlimited Everything From Only $10/month Per Line For Your Cell Phone Service!
          <span className="sm:inline-block block">
            <img
              className="mx-auto inline-block lg:w-[57px] w-10 sm:ms-2"
              src={blackStars}
              alt="black-star"
            />
          </span>
        </h1>
        <p className="max-w-[569px] mx-auto text-center sm:mb-10 font-normal text-base leading-lh_150 text-cyan-blue font-poppins sm:px-0 px-2.5 mb-12">
          Yeah, no kidding. Contact us or book an appointment now.
        </p>
        <div className="flex justify-center min-[500px]:flex-row flex-col items-center md:gap-[38px] gap-[26px]">
          <button
            onClick={openModal} // Open modal on click
            aria-label="get-start"
            className="font-semibold w-full max-w-[375px] sm:max-w-[165px] text-base leading-lh_145 font-jost text-navy-blue bg-tron-blue rounded-full px-5 py-[13.6px] border border-transparent border-solid hover:border-cyan-blue hover:bg-transparent transition-all duration-300 text-center"
          >
            Get Started Now
          </button>
          <a
            aria-label="read-more"
            href="#readmore"
            className="font-semibold w-full max-w-[375px] sm:max-w-[165px] text-base leading-lh_145 font-jost text-navy-blue bg-transparent rounded-full px-9 py-[13.6px] border border-cyan-blue border-solid hover:border-transparent hover:bg-cyan-blue hover:text-white transition-all duration-300 text-center flex items-center justify-center"
          >
            Read More
          </a>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[100]">
          <div className="bg-white  relative rounded-lg sm:h-auto h-full w-[100%] sm:w-[700px]">
            <button
              onClick={closeModal}
              className="absolute top-[2px] sm:top-5  sm:pb-1  z-[101] bg-white  rounded-full text-3xl px-2.5 left-[20px] sm:left-5  font-bold text-black"
            >
              &times;
            </button>
            <iframe
              src="https://form.jotform.com/242966086554165"
              className="w-full h-full sm:h-[600px] border-none"
              title="Get Started Form"
            />
          </div>
        </div>
      )}

      {/* Fixed Bottom Button with Transition */}
      <div
        className={`${
          showButton ? "opacity-100 translate-y-0" : "opacity-0 translate-y-10"
        } transition-all bg-white duration-500 fixed bottom-0 sm:hidden  p-4 w-full z-40 flex justify-center`}
      >
        <button
          className="bg-tron-blue font-jost flex flex-col items-center w-full font-medium px-4 py-1.5 rounded-lg"
          onClick={openModal}
        >
          <span className="text-xl">
          Get Started Now
          </span>
          <span>Click Here</span>
        </button>
      </div>
    </div>
  );
};

export default Hero;