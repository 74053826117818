// Icons component that renders different SVG icons based on the 'icon' prop
const Icons = ({ icon, className = "", ...props }) => {
  // List of available icons and their corresponding SVG elements
  const iconsList = {
    instagram: (
      <svg
        className={className}
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_b_584_711)">
          <rect width="40" height="40" rx="20" fill="#208BE4" />
          <path
            d="M20 11.8017C22.67 11.8017 22.9867 11.8117 24.0408 11.86C25.1308 11.91 26.2533 12.1583 27.0475 12.9525C27.8492 13.7542 28.09 14.8658 28.14 15.9592C28.1883 17.0133 28.1983 17.33 28.1983 20C28.1983 22.67 28.1883 22.9867 28.14 24.0408C28.0908 25.125 27.8367 26.2583 27.0475 27.0475C26.2458 27.8492 25.135 28.09 24.0408 28.14C22.9867 28.1883 22.67 28.1983 20 28.1983C17.33 28.1983 17.0133 28.1883 15.9592 28.14C14.8833 28.0908 13.735 27.8308 12.9525 27.0475C12.155 26.25 11.91 25.1275 11.86 24.0408C11.8117 22.9867 11.8017 22.67 11.8017 20C11.8017 17.33 11.8117 17.0133 11.86 15.9592C11.9092 14.8792 12.1658 13.7392 12.9525 12.9525C13.7525 12.1525 14.8683 11.91 15.9592 11.86C17.0133 11.8117 17.33 11.8017 20 11.8017ZM20 10C17.2842 10 16.9433 10.0117 15.8767 10.06C14.3308 10.1308 12.7958 10.5608 11.6783 11.6783C10.5567 12.8 10.1308 14.3317 10.06 15.8767C10.0117 16.9433 10 17.2842 10 20C10 22.7158 10.0117 23.0567 10.06 24.1233C10.1308 25.6675 10.5625 27.2067 11.6783 28.3217C12.7992 29.4425 14.3333 29.8692 15.8767 29.94C16.9433 29.9883 17.2842 30 20 30C22.7158 30 23.0567 29.9883 24.1233 29.94C25.6683 29.8692 27.205 29.4383 28.3217 28.3217C29.4442 27.1992 29.8692 25.6683 29.94 24.1233C29.9883 23.0567 30 22.7158 30 20C30 17.2842 29.9883 16.9433 29.94 15.8767C29.8692 14.3308 29.4383 12.795 28.3217 11.6783C27.2025 10.5592 25.6642 10.13 24.1233 10.06C23.0567 10.0117 22.7158 10 20 10Z"
            fill="white"
          />
          <path
            d="M20.0002 14.8652C17.1644 14.8652 14.8652 17.1644 14.8652 20.0002C14.8652 22.8361 17.1644 25.1352 20.0002 25.1352C22.8361 25.1352 25.1352 22.8361 25.1352 20.0002C25.1352 17.1644 22.8361 14.8652 20.0002 14.8652ZM20.0002 23.3336C18.1594 23.3336 16.6669 21.8411 16.6669 20.0002C16.6669 18.1594 18.1594 16.6669 20.0002 16.6669C21.8411 16.6669 23.3336 18.1594 23.3336 20.0002C23.3336 21.8411 21.8411 23.3336 20.0002 23.3336Z"
            fill="white"
          />
          <path
            d="M25.3387 15.8619C26.0014 15.8619 26.5387 15.3247 26.5387 14.6619C26.5387 13.9992 26.0014 13.4619 25.3387 13.4619C24.6759 13.4619 24.1387 13.9992 24.1387 14.6619C24.1387 15.3247 24.6759 15.8619 25.3387 15.8619Z"
            fill="white"
          />
        </g>
        <defs>
          <filter
            id="filter0_b_584_711"
            x="-62.5"
            y="-62.5"
            width="165"
            height="165"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="31.25" />
            <feComposite
              in2="SourceAlpha"
              operator="in"
              result="effect1_backgroundBlur_584_711"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_backgroundBlur_584_711"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    ),
    
    facebook: (
      <svg
        className={className}
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="40" height="40" rx="20" fill="#208BE4" />
        <path
          d="M22.9335 30V20.95H25.7627L26.2327 17.955H22.9335V15.9618C22.9335 15.0854 23.188 14.5113 24.4312 14.5113H26.2812V11.7797C25.808 11.7097 24.6528 11.6133 23.3652 11.6133C20.9805 11.6133 19.5375 12.9225 19.5375 15.6477V17.955H16.7947V20.95H19.5375V30H22.9335Z"
          fill="white"
        />
      </svg>
    ),

    linkedin: (
      <svg
        className={className}
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="40" height="40" rx="20" fill="#208BE4" />
        <path
          d="M12.3344 15.0402H16.5439V30H12.3344V15.0402ZM14.4392 10.3806C13.3133 10.3806 12.3964 11.2817 12.3964 12.4334C12.3964 13.5458 13.2823 14.4782 14.4082 14.4782H14.4392C15.6271 14.4782 16.513 13.5458 16.513 12.4334C16.482 11.2817 15.6271 10.3806 14.4392 10.3806ZM24.0714 14.8226C21.6855 14.8226 20.4179 16.1459 19.8578 17.1089V15.0402H15.6271C15.6902 16.4048 15.6271 30 15.6271 30H19.8578V21.6201C19.8578 21.2039 19.8888 20.7882 20.0328 20.4935C20.361 19.7595 21.0027 19.0256 22.0245 19.0256C23.4623 19.0256 24.0714 20.2218 24.0714 21.8428V30H28.3019V21.4066C28.3019 17.8345 26.4127 14.8226 24.0714 14.8226Z"
          fill="white"
        />
      </svg>
    ),

    chatBot: (
      <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1326_112)">
      <path d="M2.27268 0.125997C2.06604 0.197872 1.76057 0.332638 1.58986 0.440451C1.13166 0.718966 0.466816 1.47365 0.242207 1.95881L0.0445508 2.38108L0.0175977 17.1604C-0.000371093 28.0944 0.026582 32.0026 0.098457 32.1912C0.332051 32.7483 0.996895 33.0537 1.68869 32.928C1.97619 32.874 2.43439 32.4608 5.12072 29.7744L8.22033 26.6838L22.4606 26.6658L36.7008 26.6389L37.1321 26.4412C37.725 26.1627 38.4977 25.408 38.7852 24.8061L39.0367 24.303V13.342V2.38108L38.8391 1.94983C38.5606 1.35686 37.8059 0.584201 37.2039 0.2967L36.7008 0.0451379L19.6754 0.0271692C5.84846 0.0181848 2.57814 0.0361536 2.27268 0.125997ZM9.74768 9.47873C11.7063 9.99084 13.018 11.9854 12.6856 13.953C12.5328 14.8424 12.1914 15.4893 11.5535 16.1362C10.9785 16.7022 10.4574 17.0076 9.6758 17.2143C8.31916 17.5737 6.8008 17.0885 5.80353 15.9834C4.41994 14.4381 4.43791 12.201 5.83947 10.6647C6.86369 9.55061 8.31916 9.11037 9.74768 9.47873ZM20.5289 9.47873C22.4875 9.99084 23.7992 11.9854 23.4668 13.953C23.3141 14.8424 22.9727 15.4893 22.3348 16.1362C21.7598 16.7022 21.2387 17.0076 20.4571 17.2143C19.1004 17.5737 17.5821 17.0885 16.5848 15.9834C15.2012 14.4381 15.2192 12.201 16.6207 10.6647C17.6449 9.55061 19.1004 9.11037 20.5289 9.47873ZM31.3102 9.47873C33.2688 9.99084 34.5805 11.9854 34.2481 13.953C34.0953 14.8424 33.7539 15.4893 33.116 16.1362C32.541 16.7022 32.0199 17.0076 31.2383 17.2143C29.8817 17.5737 28.3633 17.0885 27.366 15.9834C25.9824 14.4381 26.0004 12.201 27.402 10.6647C28.4262 9.55061 29.8817 9.11037 31.3102 9.47873Z" fill="#001F33"/>
      <path d="M8.07695 12.1469C7.67266 12.3715 7.36719 12.8926 7.36719 13.3418C7.36719 14.0426 8.04102 14.7344 8.72383 14.7344C9.19102 14.7344 9.73008 14.4199 9.95469 14.0246C10.6375 12.8117 9.28086 11.4461 8.07695 12.1469Z" fill="#001F33"/>
      <path d="M18.8582 12.1469C18.4539 12.3715 18.1484 12.8926 18.1484 13.3418C18.1484 14.0426 18.8223 14.7344 19.5051 14.7344C19.9723 14.7344 20.5113 14.4199 20.7359 14.0246C21.4188 12.8117 20.0621 11.4461 18.8582 12.1469Z" fill="#001F33"/>
      <path d="M29.6395 12.1469C29.2352 12.3715 28.9297 12.8926 28.9297 13.3418C28.9297 14.0426 29.6035 14.7344 30.2863 14.7344C30.7535 14.7344 31.2926 14.4199 31.5172 14.0246C32.2 12.8117 30.8434 11.4461 29.6395 12.1469Z" fill="#001F33"/>
      <path d="M41.7501 20.4934C41.7142 23.9973 41.6962 24.2488 41.5165 24.8867C40.9056 26.9531 39.3782 28.4805 37.2849 29.1184L36.611 29.325L25.2638 29.3609L13.9165 29.3879L13.9435 33.359L13.9704 37.3301L14.2669 37.932C14.6083 38.6238 15.1384 39.1359 15.8931 39.5043L16.3962 39.7559L27.0876 39.7828L37.779 39.8008L40.7438 42.7566C42.379 44.3738 43.8075 45.7754 43.9333 45.8563C44.0681 45.9461 44.3376 46 44.6521 46C45.2091 46 45.5505 45.8203 45.829 45.407C45.9907 45.1555 45.9997 44.7332 45.9997 32.2988V19.4512L45.7841 18.9391C45.236 17.6004 44.1938 16.9086 42.6216 16.8188L41.795 16.7738L41.7501 20.4934Z" fill="#001F33"/>
      </g>
      <defs>
      <clipPath id="clip0_1326_112">
      <rect width="46" height="46" fill="white"/>
      </clipPath>
      </defs>
      </svg>
      
    ),
    phone: (
      <svg
        className={className}
        width="46"
        height="46"
        viewBox="0 0 46 46"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M41.666 31.4598C41.3456 33.8946 40.1498 36.1296 38.302 37.7472C36.4543 39.3648 34.0808 40.2545 31.625 40.2501C17.3578 40.2501 5.75002 28.6423 5.75002 14.3751C5.74561 11.9193 6.63531 9.54588 8.25294 7.69811C9.87057 5.85033 12.1055 4.65458 14.5403 4.33419C15.156 4.25901 15.7795 4.38497 16.3178 4.69327C16.856 5.00157 17.2801 5.47568 17.5267 6.04481L21.3217 14.5171V14.5386C21.5106 14.9743 21.5885 15.4499 21.5487 15.9231C21.5089 16.3962 21.3525 16.8522 21.0935 17.2501C21.0612 17.2986 21.027 17.3436 20.9911 17.3885L17.25 21.8232C18.5959 24.558 21.4565 27.3935 24.2273 28.7429L28.6009 25.0216C28.6438 24.9855 28.6888 24.9519 28.7356 24.921C29.1333 24.6558 29.5908 24.4939 30.0667 24.45C30.5427 24.406 31.022 24.4815 31.4615 24.6694L31.4849 24.6802L39.9499 28.4734C40.5201 28.7192 40.9953 29.1429 41.3047 29.6812C41.614 30.2195 41.7407 30.8435 41.666 31.4598Z"
          fill="#001F33"
        />
      </svg>
    ),
    email: (
      <svg
        className={className}
        width="46"
        height="46"
        viewBox="0 0 46 46"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M38.333 7.6665H7.66634C5.55801 7.6665 3.85217 9.3915 3.85217 11.4998L3.83301 34.4998C3.83301 36.6082 5.55801 38.3332 7.66634 38.3332H38.333C40.4413 38.3332 42.1663 36.6082 42.1663 34.4998V11.4998C42.1663 9.3915 40.4413 7.6665 38.333 7.6665ZM37.5663 15.8123L24.0155 24.284C23.4022 24.6673 22.5972 24.6673 21.9838 24.284L8.43301 15.8123C8.24082 15.7044 8.07252 15.5587 7.93829 15.3839C7.80407 15.2091 7.70671 15.0088 7.65211 14.7953C7.59751 14.5818 7.5868 14.3594 7.62064 14.1416C7.65448 13.9238 7.73215 13.7151 7.84896 13.5282C7.96578 13.3413 8.1193 13.1801 8.30023 13.0542C8.48117 12.9284 8.68576 12.8406 8.90162 12.7961C9.11749 12.7516 9.34013 12.7514 9.55609 12.7954C9.77204 12.8395 9.97681 12.9269 10.158 13.0523L22.9997 21.0832L35.8413 13.0523C36.0225 12.9269 36.2273 12.8395 36.4433 12.7954C36.6592 12.7514 36.8819 12.7516 37.0977 12.7961C37.3136 12.8406 37.5182 12.9284 37.6991 13.0542C37.8801 13.1801 38.0336 13.3413 38.1504 13.5282C38.2672 13.7151 38.3449 13.9238 38.3787 14.1416C38.4125 14.3594 38.4018 14.5818 38.3472 14.7953C38.2926 15.0088 38.1953 15.2091 38.0611 15.3839C37.9268 15.5587 37.7585 15.7044 37.5663 15.8123Z"
          fill="#001F33"
        />
      </svg>
    ),
    menu: (
      <svg
        className={className}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3 18V16H21V18H3ZM3 13V11H21V13H3ZM3 8V6H21V8H3Z"
          fill="#001F33"
        />
      </svg>
    ),
    fee: (
      <svg
        width="57"
        height="57"
        viewBox="0 0 57 57"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M30.0831 4.87681C25.1431 7.47348 22.7998 11.4001 22.7998 17.1001C22.7998 25.2068 27.9931 30.4001 36.0998 30.4001C44.2065 30.4001 49.3998 25.2068 49.3998 17.1001C49.3998 11.4001 47.0565 7.47348 42.3065 5.00348C39.3298 3.48348 32.8065 3.42015 30.0831 4.87681ZM37.9998 8.80348C37.9998 9.50015 38.5698 10.2601 39.2665 10.4501C39.9631 10.6401 40.8498 11.4001 41.1665 12.0968C41.7365 13.1101 41.6098 13.3001 40.3431 13.3001C39.5831 13.3001 38.5698 12.9835 38.1898 12.6035C37.1765 11.5901 33.9465 12.1601 33.6931 13.4268C33.4398 14.5668 34.8331 15.3901 38.1265 16.0868C40.4065 16.5935 41.7998 18.1135 41.7998 20.0768C41.7998 21.9135 40.2165 24.0668 38.8231 24.0668C38.3798 24.0668 37.9998 24.6368 37.9998 25.3335C37.9998 26.3468 37.5565 26.6001 36.0998 26.6001C34.6431 26.6001 34.1998 26.3468 34.1998 25.3335C34.1998 24.6368 33.8831 24.0668 33.4398 24.0668C32.5531 24.0668 30.3998 22.1668 30.3998 21.4068C30.3998 20.6468 33.1865 20.7735 34.0098 21.5968C35.2131 22.8001 38.3165 22.0401 38.5065 20.4568C38.6965 19.0635 38.3798 18.8735 34.3265 18.0501C30.0831 17.1635 29.1965 12.3501 32.9331 10.4501C34.0098 9.88015 34.8331 9.05681 34.8331 8.55015C34.8331 7.98015 35.4665 7.60015 36.4165 7.60015C37.5565 7.60015 37.9998 7.91681 37.9998 8.80348Z"
          fill="#001F33"
        />
        <path
          d="M8.04314 30.21L3.7998 31.7934V39.6467C3.7998 43.9534 4.05314 47.5 4.3698 47.5C4.68647 47.5 6.52314 46.8034 8.48647 45.9167C10.4498 45.03 12.6665 44.3334 13.3631 44.3334C14.0598 44.3334 18.4931 45.9167 23.1798 47.8167C27.9298 49.7167 32.5531 51.3 33.4398 51.3C34.3898 51.2367 39.1398 49.02 44.0165 46.2334L52.8831 41.2934V38.8234C52.8831 36.6067 52.7565 36.4167 50.8565 36.2267C49.5898 36.1 46.5498 36.9867 42.3065 38.76L35.7831 41.5467V39.0134V36.5434L26.5998 32.49C16.1498 27.93 14.6931 27.8034 8.04314 30.21Z"
          fill="#001F33"
        />
      </svg>
    ),
    savings: (
      <svg
        width="57"
        height="57"
        viewBox="0 0 57 57"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.1272 2.9134C17.9872 3.54673 16.2772 5.0034 15.3272 6.20673C13.8072 8.17006 13.6172 8.9934 13.6172 13.2367C13.6172 17.3534 13.8705 18.3667 15.2005 20.2667L16.7839 22.4834L17.1639 17.1001C17.5439 11.2101 18.1139 9.62673 20.6472 7.6634C23.8772 5.13006 29.0705 6.46006 31.1605 10.3234C31.5405 11.0834 32.0472 14.1234 32.2372 17.1001L32.6172 22.4834L34.2005 20.2667C36.2905 17.3534 36.7339 11.3367 35.1505 7.98006C33.3139 4.11673 30.2739 2.28006 25.3972 2.02673C22.4205 1.90006 20.6472 2.1534 19.1272 2.9134Z"
          fill="#001F33"
        />
        <path
          d="M21.6605 10.2601C21.0905 10.8301 20.9005 15.2001 20.9005 26.0934C20.9005 34.3901 20.8372 41.1668 20.7105 41.1668C20.5838 41.1668 19.0005 40.4701 17.2272 39.5834C13.6172 37.8734 7.79049 37.4301 6.46049 38.7601C4.62382 40.5968 5.57382 42.2434 10.3238 44.9668C12.7938 46.4868 16.1505 48.7668 17.7338 50.0334C23.8138 55.0368 23.9405 55.1001 35.0872 55.1001H45.0938L47.2472 52.9468L49.4638 50.7301L49.2738 40.7234L49.0838 30.7168L46.5505 30.5268C45.1572 30.4001 43.5105 29.8934 42.9405 29.3234C42.3072 28.7534 40.9772 28.4368 39.3305 28.5634C37.6205 28.6901 36.4172 28.4368 35.5305 27.6134C34.6438 26.7901 33.5038 26.5368 31.5405 26.6634L28.8172 26.9168L28.6272 19.0001C28.5638 14.5668 28.1838 10.8301 27.7405 10.3234C26.9172 9.3101 22.6738 9.24677 21.6605 10.2601Z"
          fill="#001F33"
        />
      </svg>
    ),
    process: (
      <svg
        width="57"
        height="57"
        viewBox="0 0 57 57"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.4004 5.69985C11.4004 7.40985 11.5904 7.59985 13.3004 7.59985C15.0104 7.59985 15.2004 7.40985 15.2004 5.69985C15.2004 3.98985 15.0104 3.79985 13.3004 3.79985C11.5904 3.79985 11.4004 3.98985 11.4004 5.69985Z"
          fill="#001F33"
        />
        <path
          d="M19 5.69985C19 7.40985 19.19 7.59985 20.9 7.59985C22.61 7.59985 22.8 7.40985 22.8 5.69985C22.8 3.98985 22.61 3.79985 20.9 3.79985C19.19 3.79985 19 3.98985 19 5.69985Z"
          fill="#001F33"
        />
        <path
          d="M26.5996 5.69985C26.5996 7.40985 26.7896 7.59985 28.4996 7.59985C30.2096 7.59985 30.3996 7.40985 30.3996 5.69985C30.3996 3.98985 30.2096 3.79985 28.4996 3.79985C26.7896 3.79985 26.5996 3.98985 26.5996 5.69985Z"
          fill="#001F33"
        />
        <path
          d="M34.2002 5.69985C34.2002 7.40985 34.3902 7.59985 36.1002 7.59985C37.8102 7.59985 38.0002 7.40985 38.0002 5.69985C38.0002 3.98985 37.8102 3.79985 36.1002 3.79985C34.3902 3.79985 34.2002 3.98985 34.2002 5.69985Z"
          fill="#001F33"
        />
        <path
          d="M41.7998 5.69985C41.7998 7.40985 41.9898 7.59985 43.6998 7.59985C45.4098 7.59985 45.5998 7.40985 45.5998 5.69985C45.5998 3.98985 45.4098 3.79985 43.6998 3.79985C41.9898 3.79985 41.7998 3.98985 41.7998 5.69985Z"
          fill="#001F33"
        />
        <path
          d="M10.3233 11.21C9.69 11.4 9.5 15.2633 9.5 27.4233V43.3833L18.3033 48.26C23.18 50.9833 27.74 53.2 28.5 53.2C29.26 53.2 33.82 50.9833 38.6967 48.3233L47.5 43.3833V27.36V11.4H30.1467C20.5833 11.4 12.4133 11.2733 11.9067 11.1466C11.4633 10.9566 10.7033 11.02 10.3233 11.21ZM30.0833 23.4333C31.2233 26.8533 31.6033 27.1066 36.7333 27.36L39.5833 27.55L36.29 29.9566L32.9967 32.3633L34.01 35.15C35.53 39.6466 35.0233 40.3433 31.8567 38.0633C30.3367 36.9866 28.88 36.1 28.5 36.1C28.12 36.1 26.6633 36.9866 25.1433 38.0633C21.9767 40.3433 21.47 39.6466 22.99 35.15L24.0033 32.3633L20.71 29.9566L17.4167 27.55L20.2667 27.36C25.3967 27.1066 25.7767 26.8533 26.9167 23.4333C27.4867 21.7233 28.1833 20.2666 28.5 20.2666C28.8167 20.2666 29.5133 21.7233 30.0833 23.4333Z"
          fill="#001F33"
        />
      </svg>
    ),
    cross: (
      <svg
        className="w-4 h-4"
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="1.41406"
          width="18"
          height="2"
          transform="rotate(45 1.41406 0)"
          fill="#001F33"
        />
        <rect
          x="0.0712891"
          y="12.799"
          width="18"
          height="2"
          transform="rotate(-45 0.0712891 12.799)"
          fill="#001F33"
        />
      </svg>
    ),
    selectIcon: (
      <svg
        width="23"
        height="14"
        viewBox="0 0 23 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.09062 1.08816C0.673187 1.26997 0.440188 1.74491 0.52825 2.2346C0.565688 2.44278 1.15431 3.05285 5.80081 7.69941C10.4474 12.3459 11.0574 12.9345 11.2656 12.972C11.8584 13.0786 11.5141 13.3845 17.1992 7.69941C22.8827 2.01591 22.5806 2.35578 22.4705 1.76891C22.4128 1.46128 22.0389 1.08741 21.7313 1.02972C21.1469 0.920033 21.4411 0.661533 16.2654 5.8321L11.4996 10.5933L6.76544 5.8621C4.11394 3.21228 1.95387 1.10216 1.85531 1.0656C1.61994 0.978158 1.32306 0.986908 1.09062 1.08816Z"
          fill="black"
        />
      </svg>
    ),
    leftArrow: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 19L8 12L15 5"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    rightArrow: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 5L16 12L9 19"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    minus: (
      <svg
        width="33"
        height="32"
        viewBox="0 0 33 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M24.4998 17.3307H8.49984C8.14622 17.3307 7.80708 17.1903 7.55703 16.9402C7.30698 16.6902 7.1665 16.351 7.1665 15.9974C7.1665 15.6438 7.30698 15.3046 7.55703 15.0546C7.80708 14.8045 8.14622 14.6641 8.49984 14.6641H24.4998C24.8535 14.6641 25.1926 14.8045 25.4426 15.0546C25.6927 15.3046 25.8332 15.6438 25.8332 15.9974C25.8332 16.351 25.6927 16.6902 25.4426 16.9402C25.1926 17.1903 24.8535 17.3307 24.4998 17.3307Z"
          fill="white"
        />
      </svg>
    ),
    plus: (
      <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24.4998 17.3307H17.8332V23.9974C17.8332 24.351 17.6927 24.6902 17.4426 24.9402C17.1926 25.1903 16.8535 25.3307 16.4998 25.3307C16.1462 25.3307 15.8071 25.1903 15.557 24.9402C15.307 24.6902 15.1665 24.351 15.1665 23.9974V17.3307H8.49984C8.14622 17.3307 7.80708 17.1903 7.55703 16.9402C7.30698 16.6902 7.1665 16.351 7.1665 15.9974C7.1665 15.6438 7.30698 15.3046 7.55703 15.0546C7.80708 14.8045 8.14622 14.6641 8.49984 14.6641H15.1665V7.9974C15.1665 7.64377 15.307 7.30463 15.557 7.05459C15.8071 6.80454 16.1462 6.66406 16.4998 6.66406C16.8535 6.66406 17.1926 6.80454 17.4426 7.05459C17.6927 7.30463 17.8332 7.64377 17.8332 7.9974V14.6641H24.4998C24.8535 14.6641 25.1926 14.8045 25.4426 15.0546C25.6927 15.3046 25.8332 15.6438 25.8332 15.9974C25.8332 16.351 25.6927 16.6902 25.4426 16.9402C25.1926 17.1903 24.8535 17.3307 24.4998 17.3307Z" fill="white"/>
</svg>

    ),
  };
  return icon && iconsList[icon];
};

export default Icons;
