import React, { useState, useEffect } from "react";
import backToTop from "../../assets/images/svg/back-to-top.svg";
const BackToTop = () => {
  
  const [showScroll, setShowScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
    return function cleanup() {
      window.removeEventListener("scroll", checkScrollTop);
    };
  });

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 100) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 100) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <img
        className={`fixed w-[40px] md:w-[48px]  BackToTop_height end-[20px]  z-20 cursor-pointer scrollTop sm:w-[45px] sm:end-[30px] h-[40px] md:h-[48px]  md:end-[40px] hover:scale-110 duration-300 ease-in-out transition-all ${showScroll ? "scale-100 bottom-[20px] md:bottom-[40px] sm:bottom-[30px]":"scale-0 -bottom-24"}`}
        onClick={scrollTop}
        src={backToTop}
        alt="back-to-top"
      />
    </>
  );
};

export default BackToTop;
