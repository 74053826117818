/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Input } from "./common/Input";
import PrimaryHeading from "./common/PrimaryHeading";

const FormPopup = ({ isPopupOpen, togglePopup }) => {
  
  const [submissionSuccess, setSubmissionSuccess] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  // Apply or remove the overflow-hidden class to the body when the popup is open
  useEffect(() => {
    if (isPopupOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [isPopupOpen]);

  // Handler to detect clicks on the background layer
  const handleOutsideClick = (e) => {
    if (e.target.id === "overlay") {
      togglePopup();
    }
  };

  // Handle form submission
  const onSubmit = (data) => {
    console.log(data);
    setSubmissionSuccess(true); // Show the success message
    reset(); // Reset form fields
    setTimeout(() => {
      setSubmissionSuccess(false); // Hide success message after a few seconds
    }, 3000); // Adjust the delay as needed
  };

  return (
    <>
      <div
        onClick={handleOutsideClick}
        className={`fixed p-5 inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 duration-300 ${
          isPopupOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        id="overlay"
      >
        <div
          className={`bg-white rounded-lg sm:h-[80vh] h-[95vh] shadow-lg w-full max-w-[1070px] sm:p-6 p-5 relative duration-300 overflow-auto ${
            isPopupOpen ? "scale-100" : "scale-0"
          }`}
        >
          <div className="flex justify-end -mt-2">
            <button aria-label="close"
              className="text-gray-500 text-2xl leading-lh_120 font-bold"
              onClick={togglePopup}
            >
              &#x2715;
            </button>
          </div>
          <PrimaryHeading
            className="text-center [text-shadow:0px_0px_7.2px_#00000030] mb-3"
            Children="Fill Out Your Information"
          />
          <p className="max-w-[450px] mx-auto text-center mb-16">
            Compare your current plan with new offers and see how much you can
            save.
          </p>
          {submissionSuccess ? (
            // Success Message Displayed Here Instead of the Form
            <div className="bg-gray-200 p-4 text-center text-navy-blue font-semibold my-auto">
              Thank you! Your submission has been received!
            </div>
          ) : (
            // Form is Displayed Initially
            <>
              <form className="mt-9" onSubmit={handleSubmit(onSubmit)}>
                <div className="grid col-span-1 sm:grid-cols-3 sm:gap-6 w-full">
                  <Input
                    name="name"
                    {...register("name", {
                      required: "Name is required",
                      maxLength: {
                        value: 20,
                        message: "Name cannot exceed 20 characters",
                      },
                    })}
                    error={errors.name?.message}
                  />
                  <Input
                    name="email"
                    type="email"
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i,
                        message: "Enter a valid email address",
                      },
                    })}
                    error={errors.email?.message}
                  />
                  <Input
                    name="phone"
                    type="number"
                    {...register("phone", {
                      required: "Phone number is required",
                      minLength: {
                        value: 10,
                        message: "Phone number must be exactly 10 digits",
                      },
                      maxLength: {
                        value: 10,
                        message: "Phone number must be exactly 10 digits",
                      },
                    })}
                    error={errors.phone?.message}
                  />
                  <textarea
                    className="border-b outline-none w-full border-b-gray py-[22px] lg:pb-[29px] lg:pt-5 font-poppins text-base font-normal placeholder:text-base placeholder:text-cyan-blue sm:col-span-3 resize-none"
                    placeholder="Message"
                  ></textarea>
                </div>
                <button aria-label="save"
                  type="submit"
                  className="mt-16 font-semibold max-w-[128px] w-full text-base leading-lh_145 font-jost text-navy-blue bg-tron-blue rounded-full px-9 py-[13.6px] border border-transparent border-solid hover:border-cyan-blue hover:bg-transparent transition-all duration-300"
                >
                  Save
                </button>
              </form>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default FormPopup;
