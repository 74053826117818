import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules"; // Add desired modules
import "swiper/css";
import "swiper/css/pagination";
import PrimaryHeading from "./common/PrimaryHeading";
import { OUR_CLIENTS_DATA } from "../utils/Helper";

const OurClients = () => {

  const [swiperKey, setSwiperKey] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  // Function to check the screen size and set states accordingly
  const handleResize = () => {
    const currentIsMobile = window.innerWidth < 1024;
    setIsMobile(currentIsMobile);
    setSwiperKey((prevKey) => prevKey + 1); // Increment the key to force re-render
  };

  useEffect(() => {
    // Set initial screen size and add resize event listener
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      // Cleanup the event listener on component unmount
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="max-w-[1375px] px-5 mx-auto mt-[173px]" id="our-client">
      <div className="bg-mint-cream lg:pt-[67px] lg:pb-[90px] pt-12 pb-5 md:px-5 sm:px-4 px-3 rounded-br_20">
        <PrimaryHeading
          className="text-center"
          Children="What Our Clients Are Saying"
        />
        <div className="max-w-[1047px] mx-auto lg:pt-[92px] md:pt-20 sm:pt-16 pt-[54px] overflow-hidden">
          <Swiper
            className="overflow-hidden lg:h-[270px]"
            key={swiperKey}
            spaceBetween={10}
            slidesPerView={1}
            pagination={{ clickable: true }}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            modules={[Pagination,Autoplay]}
            breakpoints={{
              0: {
                pagination: {
                  clickable: true,
                  el: ".custom-pagination", // Enable pagination for screens below 1024px
                  renderBullet: (index, className) => {
                    return `<span class="${className} custom-bullet"></span>`;
                  },
                },
              },
              1024: {
                slidesPerView: 1,
                spaceBetween: 36,
                direction: "vertical",
              },
            }}
          >
            {OUR_CLIENTS_DATA.map((obj, index) => (
              <SwiperSlide key={index} className="overflow-hidden lg:px-4">
                <div className="flex lg:flex-row justify-center flex-col gap-12">
                  <div className="flex justify-center">
                    <div className="relative">
                      <img
                        src={obj.clientImg}
                        alt="client-profile-image"
                        className="sm:w-[224px] sm:h-[224px] w-[155px] h-[155px] relative z-20 rounded-full"
                      />
                      <span className="sm:w-[212px] sm:h-[212px] w-[146px] h-[146px] rounded-full bg-tron-blue absolute lg:bottom-3.5 -left-3 -bottom-3.5"></span>
                    </div>
                  </div>
                  <div className="text-center lg:text-start">
                    <img
                      src={obj.Stars}
                      alt="rating stars"
                      className="w-[159px] mx-auto lg:mx-0 h-[26px]"
                    />
                    <p className="pt-4 max-w-[545px] lg:mx-0 mx-auto font-poppins font-normal text-base leading-lh_150 text-cyan-blue opacity-70">
                      {obj.description}
                    </p>
                    <p className="pt-[14px] font-poppins font-bold text-base leading-lh_150 text-cyan-blue">
                      {obj.name}
                    </p>
                    <p className="pt-[1px] font-poppins font-normal text-base leading-lh_150 text-cyan-blue opacity-70">
                      {obj.designation}
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      {isMobile && (
        <div className="custom-pagination mt-6 flex justify-center lg:hidden"></div>
      )}
    </div>
  );
};

export default OurClients;
