import React, { useState } from "react";
import PrimaryHeading from "./common/PrimaryHeading";
import cellPhone from "../assets/images/svg/cell-phone.svg";
import internetPlan from "../assets/images/svg/internet-plan.svg";
import girlImage from "../assets/images/webp/service-section-girl.webp";
import logoCard from "../assets/images/png/logo-popup.png";
import bill from "../assets/images/svg/bill-image.svg";

const OurService = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Function to open the modal
  const openModal = () => setIsModalOpen(true);

  // Function to close the modal
  const closeModal = () => setIsModalOpen(false);
  return (
    <div className="px-5 md:pt-[175px] pt-[146px] relative z-10">
      <div className="lg:size-[204px] size-[100px] bg-tron-blue lg:blur-[200px] blur-[170px] rounded-full absolute lg:-right-28 -z-10 -right-20 -top-16 pointer-events-none md:block hidden"></div>
      <div className="lg:size-[204px] size-[100px] bg-tron-blue lg:blur-[200px] blur-[100px] rounded-full absolute -left-28 -z-10 -bottom-24 pointer-events-none md:block hidden"></div>
      <div className="max-w-[1335px] mx-auto rounded-br_20 shadow-serviceShadow bg-mint-cream lg:py-[87px] md:py-16 pt-8 pb-16 relative z-10" id="services">
        <div className="container px-5 mx-auto xl:max-w-[1180px] flex gap-11 lg:items-center lg:flex-row flex-col">
          <div className="max-w-[604px]">
            <PrimaryHeading
              className="sm:text-start text-center sm:[text-shadow:0px_0px_0px_#00000052] [text-shadow:0px_0px_7.2px_#00000030] sm:mb-2.5 mb-[18px] font-jost"
              Children="Our Services"
            />
            <p className="sm:text-start text-center font-poppins font-normal text-base leading-lh_150 text-cyan-blue sm:mt-2.5 mt-[18px] mb-[53px] text-opacity-70 xl:pe-14">
              Our team is composed of seasoned telecommunication professionals
              with extensive experience working with all major carriers. This
              deep industry knowledge allows us to provide the best solutions
              tailored to your specific needs.
            </p>
            <div className="flex  sm:flex-row flex-col items-center gap-[26px] sm:mb-7 mb-10">
              <div className="min-w-16 min-h-16 rounded-br_5 flex justify-center items-center bg-tron-blue">
                <img src={cellPhone} alt="cell-phone" />
              </div>
              <div>
                <p className="sm:text-start text-center leading-lh_122 text-cyan-blue text-2xl font-inter font-semibold mb-[3px]">
                  Cell Phone Plans
                </p>
                <p className="sm:text-start text-center leading-lh_150 text-cyan-blue text-base font-poppins font-normal text-opacity-70">
                  We offer a range of affordable cell phone plans tailored to
                  meet your needs without compromising on quality or coverage.
                </p>
              </div>
            </div>
            <div className="flex sm:flex-row flex-col items-center gap-[26px] sm:mb-[59px] mb-[71px]">
              <div className="min-w-16 min-h-16 rounded-br_5 flex justify-center items-center bg-tron-blue">
                <img src={internetPlan} alt="internetPlan" />
              </div>
              <div>
                <p className="sm:text-start text-center leading-lh_122 text-cyan-blue text-2xl font-inter font-semibold mb-[3px]">
                  Internet Plans
                </p>
                <p className="sm:text-start text-center leading-lh_150 text-cyan-blue text-base font-poppins font-normal xl:pe-6 text-opacity-70">
                  Get high-speed internet at a fraction of the cost you're
                  paying now.
                </p>
              </div>
            </div>
            <div className="sm:justify-start justify-center flex">
            {/* <a href="https://form.jotform.com/242966086554165" aria-label="analysis" className="text-center max-sm:max-w-[375px] font-semibold w-full sm:max-w-[192px] max-w-full text-base leading-lh_145 font-jost text-navy-blue bg-tron-blue rounded-full px-5 py-[13.6px] border border-transparent border-solid hover:border-cyan-blue hover:bg-transparent transition-all duration-300">
            Get a Free Analysis
            </a> */}
            <button
              onClick={openModal} // Open modal on click
              aria-label="analysis"
              className="text-center max-sm:max-w-[375px] font-semibold w-full sm:max-w-[192px] max-w-full text-base leading-lh_145 font-jost text-navy-blue bg-tron-blue rounded-full px-5 py-[13.6px] border border-transparent border-solid hover:border-cyan-blue hover:bg-transparent transition-all duration-300"
            >
              Get a Free Analysis
            </button>            
            </div>
          </div>
          <div className="lg:max-w-[491px] w-full lg:mt-0 mt-6">
            <div className="relative">
            <img className="lg:min-w-[491px] lg:min-h-[480px] sm:w-[491px] sm:h-[480px]  mx-auto" src={girlImage} alt="girl" />
            <img className="absolute shadow-logoCard bottom-9 right-0 rounded-br_5 sm:w-[193px] sm:h-[60px] w-[131px] h-[41px]" src={logoCard} alt="logoCard" />
            <img className="absolute sm:-top-8 -top-6 right-6 rounded-br_5 sm:w-[85px] sm:h-[85px] w-[57px] h-[57px]" src={bill} alt="bill" />
            </div>
          </div>
        </div>
      </div>
      
      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[100]">
          <div className="bg-white  relative rounded-lg sm:h-auto h-full w-[100%] sm:w-[700px]">
            <button
              onClick={closeModal}
              className="absolute top-[2px] sm:top-5  sm:pb-1  z-[101] bg-white  rounded-full text-3xl px-2.5 left-[20px] sm:left-5  font-bold text-black"
            >
              &times;
            </button>
            <iframe
              src="https://form.jotform.com/242966086554165"
              className="w-full h-full sm:h-[600px] border-none"
              title="Get Started Form"
            />
          </div>
        </div>
      )}

    </div>
  );
};

export default OurService;
