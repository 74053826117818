import "./App.css";
import Benefit from "./components/Benefit";
import ChooseUs from "./components/ChooseUs";
import BackToTop from "./components/common/BackToTop";
import ContactUs from "./components/ContactUs";
import Footer from "./components/Footer";
import GigWorker from "./components/GigWorker";
import Header from "./components/Header";
import Hero from "./components/Hero";
import { MakeSwitch } from "./components/MakeSwitch";
import WhatsAppButton from "./components/wabutton";
import OurClients from "./components/OurClients";
import OurService from "./components/OurService";
import { SwitchCalculate } from "./components/SwitchCalculate";
import YourTrun from "./components/YourTrun";

function App() {
  return (
    <div className="overflow-x-hidden">
      <Header />
      <Hero />
      <WhatsAppButton phoneNumber={"+16467121079"}/>
      <SwitchCalculate />
      <ChooseUs />
      <OurService />
      <Benefit />
      <YourTrun />
      <GigWorker />
      <MakeSwitch />
      <OurClients />
      <ContactUs />
      <Footer />
      <BackToTop />
    </div>
  );
}

export default App;
