import React from "react";
import mobile from "../assets/images/png/mobile-image.png";
import girlWithText from "../assets/images/webp/girl-with-text.webp";

const YourTrun = () => {
  return (
    <div className="px-5">
      <div className="xl:max-w-[1335px] container sm:rounded-br_14 overflow-hidden rounded-br_10 mx-auto sm:px-5 px-0 bg-tron-blue-100 bg-opacity-10 pt-2">
        <div className="flex items-end gap-3 justify-between">
          <img
            className="xl:ms-8 xl:w-[437px] xl:h-[500px] lg:h-[400px] lg:w-[350px] md:w-[350px] sm:w-[250px] md:h-[300px] sm:h-[250px] sm:object-fit object-cover mx-auto mt-auto flex items-end"
            src={mobile}
            alt="mobile-image"
          />
          <img
            className="xl:w-[688px] lg:w-[590px] md:w-[400px] w-[320px] xl:h-[514px] lg:h-[490px] md:h-[300px] h-[220px] xl:me-12 max-md:object-cover max-sm:hidden lg:object-contain lg:pt-12 xl:pt-0 xl:object-cover"
            src={girlWithText}
            alt="mobile-image"
          />
        </div>
      </div>
    </div>
  );
};

export default YourTrun;
