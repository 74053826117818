import React, { useState } from "react";
import navLogo from "../assets/images/svg/nav-logo.svg";
import { NAV_LINK_LIST } from "../utils/Helper";
import Icons from "./common/Icons";
import FormPopup from "./FormPopup";

const Header = () => {
  
  const [openNav, setOpenNav] = useState(false);
  if (openNav === true) {
    document.body.classList.add("max-md:overflow-hidden");
  } else {
    document.body.classList.remove("max-md:overflow-hidden");
  }
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  // Function to toggle popup
  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  return (
    <header className="xl:max-w-[1180px] px-5 mx-auto container">
      <nav className=" flex items-center justify-between md:pt-[18.5px] pt-2">
        <a aria-label="logo" href="/" className="relative z-50">
          <img
            className="md:w-auto md:h-auto w-[113px] h-[29px]"
            src={navLogo}
            alt="logo"
          />
        </a>
        <div
          className={`flex items-center flex-col max-sm:gap-4 justify-center md:static fixed md:w-auto w-full md:h-auto h-screen top-0 transition-all duration-300 max-md:bg-mint-cream z-40 ${
            openNav ? " md:bg-transparent right-0" : "-right-[100%]"
          } `}
        >
          <ul className="p-0 m-0 flex items-center lg:gap-6 gap-4 transition-all duration-500 sm_nav">
            {NAV_LINK_LIST.map((link, index) => {
              return (
                <li key={index}>
                  <a
                    aria-label="navigation-links"
                    onClick={() => setOpenNav(!openNav)}
                    href={link.url}
                    className="font-jost font-normal md:text-base sm:text-xl text-base text-cyan-blue leading-lh_145 relative links_hover"
                  >
                    {link.title}
                  </a>
                </li>
              );
            })}
          </ul>
          <button
            aria-label="contact-us"
            onClick={() => {
              togglePopup();
              setOpenNav(!openNav);
            }}
            className="sm:hidden font-semibold text-base leading-lh_145 font-jost text-navy-blue bg-tron-blue rounded-full px-9 py-[13.6px] border border-transparent border-solid hover:border-cyan-blue hover:bg-transparent transition-all duration-300"
          >
            Contact Us
          </button>
        </div>
        <div className="flex items-center gap-3">
          <button
            aria-label="contact-us"
            onClick={togglePopup}
            className="max-sm:hidden font-semibold text-base leading-lh_145 font-jost text-navy-blue bg-tron-blue rounded-full px-9 py-[13.6px] border border-transparent border-solid hover:border-cyan-blue hover:bg-transparent transition-all duration-300"
          >
            Contact Us
          </button>
          <span
            onClick={() => setOpenNav(!openNav)}
            className="md:hidden z-50 cursor-pointer  "
          >
            {openNav ? <Icons icon="cross" /> : <Icons icon="menu" />}
          </span>
        </div>
      </nav>
      <FormPopup isPopupOpen={isPopupOpen} togglePopup={togglePopup} />
    </header>
  );
};

export default Header;
