import React, { useState } from "react";
import PrimaryHeading from "./common/PrimaryHeading";
import circle from "../assets/images/png/hero-circle.png";
import Icons from "./common/Icons";
import { CHOOSE_US_DATA_LIST } from "../utils/Helper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay, Pagination } from "swiper/modules";

const ChooseUs = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Function to open the modal
  const openModal = () => setIsModalOpen(true);

  // Function to close the modal
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className="xl:pt-[223px] lg:pt-[180px] pt-[157px] relative">
      <div className="lg:size-[204px] size-[100px] bg-tron-blue lg:blur-[200px] blur-[170px] rounded-full absolute lg:-left-28 -z-10 -left-20 top-24 pointer-events-none md:block hidden"></div>

      <img
        className="animate-spin-slow absolute xl:top-[200px] lg:top-[80px] md:top-14 lg:right-11 md:right-4 xl:size-[161px] md:size-[100px] md:block hidden"
        src={circle}
        alt="circle"
      />
      <div className="lg:size-[204px] size-[100px] bg-light-yellow lg:blur-[120px] blur-[170px] rounded-full absolute lg:-right-28 -z-10 -right-20 top-[400px] pointer-events-none md:block hidden"></div>
      <div className="container mx-auto px-5 xl:max-w-[1180px]">
        <PrimaryHeading
          className="text-center [text-shadow:0px_0px_7.2px_#00000030] sm:mb-3 mb-[18px] font-jost"
          Children="Why Should You Choose Us?"
        />
        <p className="sm:font-inter font-bold text-2xl sm:leading-lh_120 leading-lh_145 sm:mb-3 mb-[18px] font-jost text-cyan-blue text-center">
          Save Up to 70% on Your Monthly Bill
        </p>
        <p className="max-w-[648px] mx-auto text-center font-poppins font-normal text-base leading-lh_150 text-cyan-blue sm:px-0 px-4 md:mb-[77px] mb-12">
          By choosing our recommended carrier, you can save up to 70% compared
          to the current costs with major carriers like AT&T and Verizon. These
          significant savings can help you better allocate your business or
          personal budget.
        </p>
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          pagination={{ clickable: true }}
          modules={[Pagination, Autoplay]}
          breakpoints={{
            640: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
          className="!pb-4 md:!pb-6 lg:!pb-0"
        >
          {CHOOSE_US_DATA_LIST.map((obj, index) => (
            <SwiperSlide key={index}>
              <div className="flex-col w-full min-h-[283px] flex justify-center items-center xl:px-9 lg:px-0 px-9 pt-[30px] pb-[45px] lg:border-r border-r-navy-blue">
                <div className="size-[88px] rounded-br_5 bg-tron-blue-200 flex justify-center items-center shadow-chooseUs">
                  <Icons icon={obj.icon} />
                </div>
                <p className="mt-7 mb-4 leading-lh_122 text-dark-blue text-2xl font-inter font-semibold">
                  {obj.title}
                </p>
                <p className="text-base font-normal leading-lh_150 text-cyan-blue text-opacity-70 font-poppins max-w-[282px] mx-auto text-center">
                  {obj.description}
                </p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="flex justify-center">
          <button
              onClick={openModal} // Open modal on click
              aria-label="get-start"
              className="font-semibold w-full max-w-[375px] sm:max-w-[165px] text-base leading-lh_145 font-jost text-navy-blue bg-tron-blue rounded-full px-5 py-[13.6px] border border-transparent border-solid hover:border-cyan-blue hover:bg-transparent transition-all duration-300 text-center"
            >
              Get Started Now
          </button>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[100]">
          <div className="bg-white  relative rounded-lg sm:h-auto h-full w-[100%] sm:w-[700px]">
            <button
              onClick={closeModal}
              className="absolute top-[2px] sm:top-5  sm:pb-1  z-[101] bg-white  rounded-full text-3xl px-2.5 left-[20px] sm:left-5  font-bold text-black"
            >
              &times;
            </button>
            <iframe
              src="https://form.jotform.com/242966086554165"
              className="w-full h-full sm:h-[600px] border-none"
              title="Get Started Form"
            />
          </div>
        </div>
      )}

    </div>
  );
};

export default ChooseUs;
